import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from './components/Navigation';
import Mainpage from './components/MainPage';
import ReactGA from 'react-ga';
// import RouteChangeTracker from './RouteChangeTracker';

const TRACKING_ID = 'UA-12341234-1'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>
      <Navigation />
      <Router>
        {/* <RouteChangeTracker /> */}
        <Mainpage />
        {/*
        <Intro />
        <Contact />
        <SideBar />
        <Projects />
        <About />
        <Footer /> */}
      </Router>
    </>
  );
}

export default App;
