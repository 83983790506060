import "./About.css"
import React from "react"

const About = () => {
  return (
    <div className="about-container" id="about-container">
      <div className="about-me-intro" id="about-me-intro">
        About
      </div>
      <div className="about-section-container">
        <p
          className="about-me"
          data-sr-id="0"
          style={{
            visibility: "visible",
            opacity: 1,
            transition: "opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0.2s",
          }}
        >
          I'm a <span>nurse</span> turned <span>Software Engineer.</span> It
          might seem like a leap, and it was in some ways, but the two have more
          in common than you'd think. In both fields, it's all about{" "}
          <span>solving problems</span> though these days, I'm doing it with
          code instead of a stethescope. <br></br>
          <br></br>
          <span>JavaScript</span> and <span>Python</span> are my go-to tools,
          not just because they're powerful but because they allow me to bring
          in the <span>personalized</span> and <span>human-centered</span>{" "}
          approach from nursing into the tech world.
        </p>
        <div
          className="tech-stack-container"
          data-sr-id="2"
          style={{
            visibility: "visible",
            opacity: 1,
            transition: "opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0.2s",
          }}
        >
          <div>JavaScript</div>
          <div>TypeScript</div>
          <div>Python</div>
          <div>Django</div>
          <div>React.js</div>
          <div>React Admin</div>
          <div>Redux</div>
          <div>PostgreSQL</div>
          <div>Express</div>
          <div>SQLite</div>
          <div>Canva</div>
          <div>SQLAlchemy</div>
          <div>Flask</div>
          <div>Sequelize</div>
          <div>AWS S3</div>
          <div>AWS CloudFront</div>
          <div>Docker</div>
          <div>Redis</div>
          <div>Github</div>
          <div>Canva</div>
        </div>
      </div>
    </div>
  )
}

export default About
