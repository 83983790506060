import "./Footer.css"
import React from "react"

const Footer = () => {
    return (
        <a className="attribution" href="https://davidmejia.xyz/" target="_blank" rel="noopener noreferrer">
            Theme Credit: David Mejia
        </a>
    );
}

export default Footer