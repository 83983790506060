import "./intro.css"
import React from "react"


const Intro = () => {

    return (
        <div className="intro-container">
            <div className="intro">
            <div className="profile-image">
                {/* <img src="https://res.cloudinary.com/dgxpqnbwn/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1701649714/LInkedIn_2_nlyrrr.jpg" alt="Sean Hoang smiling" /> */}
            </div>
                <span className="intro-name">Sean Hoang</span>
                <br></br>
                Full-Stack{" "}
                <span class="intro-role">Software Engineer</span>
                .
                <p className="intro-speech">
                    <div>
                        Finding solutions
                    </div>
                    <div>
                        one problem at a time.
                    </div>
                </p>
            </div>
        </div>
    )
}

export default Intro